import React from "react"
import { Link } from "gatsby"
import Laptop from "./laptop"
import { Helmet } from "react-helmet"
import Layout from "../../../gatsby-theme-doc/src/components/Layout"

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="ABC" content="ABC" />
      <title>UI5CN</title>
      <link rel="icon" type="image/png" href="./icon.png" sizes="16x16" />
    </Helmet>

    <div className="index-hero">
      <div className="index-hero-inner">
        <h1 className="index-hero-project-tagline">ULab β</h1>
        <div className="index-ctas">
          <Link
            className="index-ctas-get-started-button"
            to="/docs/introduction"
          >
            Get Started
          </Link>
          <Laptop />
        </div>
      </div>
    </div>

    <div className="section">
      <div className="container text--center margin-bottom--xl">
        <div className="row">
          <div className="col">
            <span
              alt="Ready for Translations"
              className="featureImage"
              style={{
                fontSize: "100px",
              }}
            >
              📖
            </span>

            <h3>Easy to Read</h3>
            <p className="padding-horiz--md">
              Labs are structured based on learning curve of new concept and
              technology
            </p>
          </div>
          <div className="col">
            <span
              alt="Ready for Translations"
              className="featureImage"
              style={{
                fontSize: "100px",
              }}
            >
              🖥️
            </span>

            <h3>Easy to Test</h3>
            <p className="padding-horiz--md">
              Labs are structured based on learning curve of new concept and
              technology
            </p>
          </div>
          <div className="col">
            <span
              alt="Ready for Translations"
              className="featureImage"
              style={{
                fontSize: "100px",
              }}
            >
              🔍
            </span>

            <h3>Easy to Explore</h3>
            <p className="padding-horiz--md">
              Labs are written in clear steps for you to read and Test the
              outcome
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
